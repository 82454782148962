<template>
  <div class="about">
    <section class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0" :style="{ backgroundImage: 'url(' + require('../../public/assets/i/pageTitle.jpg') + ')' }">
      <div class="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h1 class="display-1 mb-3 text-white">{{ langcontent.corporate }}</h1>
            <nav class="d-inline-block" aria-label="breadcrumb">
              <ol class="breadcrumb text-white">
                <li class="breadcrumb-item"><router-link to="/">{{ langcontent.home }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ langcontent.corporate }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light">
      <div class="container py-14">
        <div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center">
          <div class="col-lg-6 position-relative">
            <div class="btn btn-circle btn-primary disabled position-absolute counter-wrapper flex-column d-none d-md-flex" style="top: 50%; left: 50%; transform: translate(-50%, -50%); width: 170px; height: 170px;">
              <h3 class="text-white mb-1 mt-n2"><span class="counter counter-lg">{{new Date().getFullYear() - 2000}}+</span></h3>
              <p>{{ langcontent.ctext }}</p>
            </div>
            <div class="row gx-md-5 gy-5 align-items-center">
              <div class="col-md-6 d-none d-md-block">
                <div class="row gx-md-5 gy-5">
                  <div class="col-md-10 offset-md-2">
                    <figure class="rounded"><img :src="data.img[0]" :alt="data.title"></figure>
                  </div>
                  <div class="col-md-12">
                    <figure class="rounded"><img :src="data.img[1]" :alt="data.title"></figure>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <figure class="rounded"><img :src="data.img[2]" :alt="data.title"></figure>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <h3 class="display-3 mb-10">{{ data.title }}</h3>
            <p class="mb-7">{{data.desc}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iso from "../axios";
export default {
  data() {
    return {
      data: [],
      langcontent: []
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Bimark | '+this.langcontent.corporate;
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('about')
        .then(response => {
          this.data = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
